<template>
  <v-autocomplete
    v-model="innerValue"
    v-bind="$attrs"
    v-on="$listeners"
    item-text="name"
    item-value="id"
    :loading="loading"
    :items="items"
    :label="$t('hr.expense.type')"
    color="tertiary"
    clearable
  >
    <template slot="item" slot-scope="data">
      <template>
        <v-list-item-avatar size="30" color="primary">
          <span class="white--text font-weight-bold">{{ data.item.name.substring(0, 2).toUpperCase() }}</span>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title v-html="data.item.name"></v-list-item-title>
        </v-list-item-content>
      </template>
    </template>
  </v-autocomplete>
</template>

<script>
  import { EXPENSE_TYPES } from "../query";

  export default {
    inheritAttrs: false,
    name: "expense-type-picker",
    props: {
      value: [String, Object, Array]
    },
    data: () => ({
      loading: false,
      selected: null,
      items: []
    }),
    computed: {
      innerValue: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit("input", val);
        }
      }
    },
    methods: {
      async fetchItems() {
        await this.$apollo
          .query({
            query: EXPENSE_TYPES
          })
          .then(({ data, errors }) => {
            if (!data.error && !errors) {
              this.items = data.expenseTypes || [];
            }
          })
          .catch(e => {
            this.$helpers.showNotification(e.message);
          });
      }
    },
    created() {
      this.fetchItems();
    }
  };
</script>
